<script setup lang="ts">
import dayjs from 'dayjs';
import { useI18n } from 'vue-i18n';
import { useOfTable } from '../../composables/ofTable';
import type { ProductsQueryQuery } from '~/types/graphql/graphql';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

// Components
import { cloneDeep } from '@apollo/client/utilities';
import type { Filter } from '~/components/ui/data/DataTable.vue';

// Types
type product = NonNullable<ProductsQueryQuery['products_by_pk']>;

// Composables
const { t } = useI18n();
const { headers } = useOfTable();
const { getNewEPIType } = useEPI();

// Store
const productService = productStore();
const toast = toastStore();

// Data
const seletedUnit = ref<'cost_by_quantity' | 'epi' | 'co2'>('epi');
const fabricationOrder = ref<product['fabrication_order_products'][0]['fabrication_order'] | null>(null);
const ofModal = ref<boolean>(false);
const actions = ref<TableAction[]>([{ key: 'view', label: t('product.action.see_details'), icon: 'Eye' }]);
const tableFilter = ref<Filter>({
  name: 'start-date',
  type: 'desc',
});

// Computed
const product = computed(() => {
  return productService.currentProduct;
});

const fabrication_orders = computed(() => {
  const fabrication_orders_products = cloneDeep(product.value?.fabrication_order_products);
  if (fabrication_orders_products) {
    return fabrication_orders_products.sort((a, b) => {
      if (tableFilter.value?.name === 'start-date') {
        if (a.fabrication_order?.start && b.fabrication_order?.start) {
          const dateA = dayjs(a.fabrication_order?.start);
          const dateB = dayjs(b.fabrication_order?.start);
          return tableFilter.value.type === 'asc' ? dateA.diff(dateB) : dateB.diff(dateA);
        }
      } else if (tableFilter?.value?.name === 'quantiy') {
        return tableFilter?.value?.type === 'asc'
          ? a.fabrication_order?.quantity - b.fabrication_order?.quantity
          : b.fabrication_order?.quantity - a.fabrication_order?.quantity;
      } else if (tableFilter?.value?.name === 'epi') {
        return tableFilter?.value?.type === 'asc'
          ? a.fabrication_order?.fabrication_order_epis[0].epi - b.fabrication_order?.fabrication_order_epis[0].epi
          : b.fabrication_order?.fabrication_order_epis[0].epi - a.fabrication_order?.fabrication_order_epis[0].epi;
      }
      return 0;
    });
  }
  return [];
});

// Methods
const updateFilter = (filter: Filter) => {
  tableFilter.value = filter;
};

const getDuration = (item: product['fabrication_order_products'][0]) => {
  return dayjs(item.fabrication_order?.end).diff(dayjs(item.fabrication_order?.start), 'hour') + ' h';
};
const getIndicator = (item: product['fabrication_order_products'][0]) => {
  const pourcent =
    ((item.fabrication_order?.fabrication_order_epis[0].epi - product.value?.epi_standard) / product.value?.epi_standard) * 100;
  return new Intl.NumberFormat('fr-FR', {
    signDisplay: 'exceptZero',
    maximumSignificantDigits: 3,
  }).format(pourcent);
};

const getEPI = (item: product['fabrication_order_products'][0]) => {
  if (seletedUnit.value === 'co2') {
    const meter_id = item.fabrication_order?.fabrication_order_epis[0].meter_id;
    const co2 = computed(() => useCO2().getCo2byKWhByMeterId(meter_id));

    const value = (item.fabrication_order?.fabrication_order_epis[0]?.epi ?? 0) * co2.value;
    return `${value.toFixed(2)} gCO2/${item.fabrication_order?.area?.fabrication_order_unit?.symbol}`;
  } else {
    const fabrication_order_epi = item.fabrication_order?.fabrication_order_epis;
    const value = fabrication_order_epi
      ? (fabrication_order_epi.length > 0 ? fabrication_order_epi[0][seletedUnit.value] : 0).toFixed(2)
      : 0;
    return `
      ${value}
      ${getNewEPIType().find((el) => el.key === seletedUnit.value)?.label}
      /
      ${item.fabrication_order?.area?.fabrication_order_unit?.symbol ?? 'kg'}
    `;
  }
};
const clickItem = (event: string, item: product['fabrication_order_products'][0]) => {
  if (item.fabrication_order?.fabrication_order_epis.length === 0) {
    toast.showToast({ type: 'error', title: t('global.error'), message: t('epi.epi_calculating') });
    return;
  }

  const id = item.fabrication_order?.id;
  const meter = item.fabrication_order?.fabrication_order_epis?.at(0)?.meter_id;

  if (event === 'view' && id && meter) {
    fabricationOrder.value = item.fabrication_order;
    ofModal.value = true;
  }
};

const getEnergyType = (areaId: number) => {
  const meter = meterStore().getMeters.find((meter) => meter.area.id === areaId);
  return meter?.meter_type.energy_type;
};
</script>

<template>
  <ui-card :title="$t('product.fabrication_orders')">
    <ui-data-table
      :loading="productService.loading"
      :filter="tableFilter"
      :headers="headers"
      :items="fabrication_orders"
      @click-row="clickItem('view', $event)"
      @update-filter="updateFilter($event)"
    >
      <!-- Header -->
      <template #header-epi>
        <ui-dropdown v-model="seletedUnit" size="sm" :items="getNewEPIType()" placeholder="IPE" />
      </template>

      <!-- Body -->
      <template #item-of_id="{ item }">
        {{ item.fabrication_order?.client_fabrication_order_id ?? '--' }}
      </template>
      <template #item-start_date="{ item }">
        {{ item.fabrication_order ? dayjs(item.fabrication_order.start).format('DD/MM/YYYY à HH:mm') : '--' }}
      </template>
      <template #item-duration="{ item }">
        {{ getDuration(item) }}
      </template>
      <template #item-quantity="{ item }">
        {{ item.fabrication_order?.quantity.toFixed(1) }} {{ item.fabrication_order?.area?.fabrication_order_unit?.symbol ?? 'kg' }}
      </template>

      <template #item-epi="{ item }">
        <div class="flex items-center space-x-2">
          <ui-badge v-if="item.fabrication_order?.fabrication_order_epis.length === 0" color="orange">
            {{ $t('global.calculates_in_progress') }}
          </ui-badge>
          <ui-badge v-else-if="item.fabrication_order.fabrication_order_epis[0].consumption_kwh === 0" color="red">
            {{ $t('global.no_data') }}
          </ui-badge>
          <div v-else>
            {{ getEPI(item) }}
          </div>
          <ui-badge
            v-if="item.fabrication_order?.fabrication_order_epis.length !== 0 && product?.epi_standard"
            :color="item.fabrication_order?.fabrication_order_epis[0]?.epi > product?.epi_standard ? 'red' : 'primary'"
          >
            {{ getIndicator(item) }}
            %
          </ui-badge>
        </div>
      </template>
      <template #item-machine="{ item }">
        <ui-badge color="purple">
          {{ item.fabrication_order?.area?.name }}
        </ui-badge>
      </template>

      <template #item-action="{ item }">
        <ui-data-table-actions :items="actions" @click-item="clickItem($event, item)" />
      </template>
    </ui-data-table>
  </ui-card>

  <!-- Modal for zoom on a OF -->
  <modale-consumption
    v-model="ofModal"
    type="fabrication_order"
    :start="fabricationOrder?.start"
    :end="fabricationOrder?.end"
    :area-id="fabricationOrder?.area?.id"
    :energy-type="getEnergyType(fabricationOrder?.area?.id)"
    :quantity="fabricationOrder?.quantity"
    :consumption-per-unit="fabricationOrder?.fabrication_order_epis[0]?.epi"
  />
</template>
